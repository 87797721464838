import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Base from '@webLayouts/Base/Base';
import { PageModularHomesFloorPlansQuery } from '@generated/GraphqlTypes';
import Designs from '@webPages/ModularHomes/Designs';
import QAllDesigns from '@staticQueries/AllDesigns';

const IndexPage: React.FC<PageProps<PageModularHomesFloorPlansQuery>> = ({
  data,
}) => {
  const seoTitle =
    data?.modularHomesFloorPlans?.frontmatter?.seoTitleFloorPlans ||
    data?.modularHomesFloorPlans?.frontmatter?.seoTitle ||
    '';
  const seoDescription =
    data?.modularHomesFloorPlans?.frontmatter?.seoDescription || '';
  const title = data?.modularHomesFloorPlans?.frontmatter?.title || '';
  const copy: { title: string; body: string }[] = (
    data?.modularHomesFloorPlans?.frontmatter?.copy || []
  ).map(a => ({ title: a?.title || '', body: a?.body || '' }));

  return (
    <Base theme="light" title={seoTitle} description={seoDescription}>
      <QAllDesigns>
        {designs => (
          <Designs
            title={title}
            tab="floorPlans"
            designs={designs}
            copy={copy}
          />
        )}
      </QAllDesigns>
    </Base>
  );
};

export const query = graphql`
  query PageModularHomesFloorPlans {
    modularHomesFloorPlans: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "modular-homes-designs" } }
    ) {
      frontmatter {
        title
        seoTitle
        seoDescription
        seoTitleDesigns
        seoTitleFloorPlans
        copy {
          title
          body
        }
      }
    }
  }
`;

export default IndexPage;
